import * as React from "react"
import { Component } from "react"
import { MyLand, Fertilizer } from "./liveness"
import MyLandComponent from "./myLand"
import { LandType } from "./landtype"

export interface MyLandsComponentProps {
  mylands: MyLand[]
  onLandTypeChange(id: number, landtype: LandType): void
  onLandSizeChange(id: number, landsize: number): void
  onFertilizerChange(id: number, fertilizer: Fertilizer): void
  onMyLandDelete(id: number): void
}

class MyLandsComponent extends React.Component<MyLandsComponentProps> {
  render() {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Heiti</th>
              <th>Gróska</th>
              <th>Landgerð</th>
              <th>Hektarar</th>
              <th>Áburður</th>
              <th>Aðgerð</th>
            </tr>
          </thead>
          <tbody>
            {this.props.mylands.map(myland => (
              <MyLandComponent
                key={myland.id}
                myland={myland}
                onLandTypeChange={this.props.onLandTypeChange}
                onLandSizeChange={this.props.onLandSizeChange}
                onFertilizerChange={this.props.onFertilizerChange}
                onDelete={this.props.onMyLandDelete}
              />
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default MyLandsComponent
