import React, { Component } from "react"
import { TreeSpecies } from "./liveness"

export enum LandType {
  PartlyHeeledDryland = 1.9,
  WellHeeledDryland = 1.3,
  DrainedWetland = -2.2,
}

export interface LandTypeProps {
  treeSpecies?: TreeSpecies
  onSelected: (landtype: LandType) => void
}

export interface LandTypeState {
  select: LandType
}

class LandTypeDropDown extends Component<LandTypeProps, LandTypeState> {
  constructor(props: LandTypeProps) {
    super(props)
    this.state = { select: LandType.WellHeeledDryland }
    this.handleChange = this.handleChange.bind(this)
    this.props.onSelected(LandType.WellHeeledDryland)
  }

  handleChange(event) {
    this.setState({ select: event.target.value })
    this.props.onSelected(event.target.value)
  }

  render() {
    return (
      <label>
        <select value={this.state.select} onChange={this.handleChange}>
          {typeof this.props.treeSpecies == "undefined" && (
            <option value={LandType.PartlyHeeledDryland}>
              lítt gróið þurrlendi
            </option>
          )}
          {typeof this.props.treeSpecies !== "undefined" &&
            !(
              this.props.treeSpecies == TreeSpecies.AO ||
              this.props.treeSpecies == TreeSpecies.SG
            ) && (
              <option value={LandType.PartlyHeeledDryland}>
                lítt gróið þurrlendi
              </option>
            )}
          <option value={LandType.WellHeeledDryland}>
            vel gróið þurrlendi
          </option>
          <option value={LandType.DrainedWetland}>framræst votlendi</option>
        </select>
      </label>
    )
  }
}

export default LandTypeDropDown
