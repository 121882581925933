import * as React from "react"
import { Component } from "react"
import LandTypeDropDown, { LandType } from "./landtype"
import liveness_is, { TreeSpecies, Fertilizer, MyLand, Sop } from "./liveness"

export interface MyLandProps {
  myland: MyLand
  onDelete(id: number): void
  onLandTypeChange(id: number, landtype: LandType): void
  onLandSizeChange(id: number, landsize: number): void
  onFertilizerChange(id: number, fertilizer: Fertilizer): void
}

export interface MyLandState {}

class MyLandComponent extends React.Component<MyLandProps, MyLandState> {
  constructor(props: MyLandProps) {
    super(props)
    this.handleLandTypeSelected = this.handleLandTypeSelected.bind(this)
    this.handleLandSizeCange = this.handleLandSizeCange.bind(this)
    this.handleFertilizerChange = this.handleFertilizerChange.bind(this)
  }

  handleLandTypeSelected(landtype: LandType) {
    this.props.onLandTypeChange(this.props.myland.id, landtype)
  }

  handleLandSizeCange(event) {
    this.props.onLandSizeChange(this.props.myland.id, event.target.value)
  }

  handleFertilizerChange(event) {
    this.props.onFertilizerChange(this.props.myland.id, event.target.value)
  }

  render() {
    return (
      <tr>
        <td>
          {this.props.myland.id}-{TreeSpecies[this.props.myland.species]}
        </td>
        <td>{liveness_is(this.props.myland.liveness)}</td>
        <td>
          <LandTypeDropDown
            onSelected={this.handleLandTypeSelected}
            treeSpecies={this.props.myland.species}
          />
        </td>
        <td>
          <input
            type="number"
            defaultValue={1}
            onChange={this.handleLandSizeCange}
            id="landsize"
            min="0"
            max="1000"
            style={{ width: 60 }}
          />
        </td>
        <td>
          <label>
            <select
              id="fertilizer"
              value={this.props.myland.fertilizer}
              onChange={this.handleFertilizerChange}
            >
              <option value={Fertilizer.No}>Nei</option>
              <option value={Fertilizer.Yes}>Já</option>
            </select>
          </label>
        </td>
        <td>
          <button onClick={() => this.props.onDelete(this.props.myland.id)}>
            Eyða
          </button>
        </td>
      </tr>
    )
  }
}

export default MyLandComponent
