import { LandType } from "./landtype"
import { Groska } from "."

export default function liveness_is(liveness: Liveness): string {
  switch (liveness) {
    case 1:
      return "AÖ9"
    case 2:
      return "AÖ16"
    case 3:
      return "AÖ21"
    case 4:
      return "SG5"
    case 5:
      return "SG8-11"
    case 6:
      return "SG14-19"
    case 7:
      return "SF5"
    case 8:
      return "SF10"
    case 9:
      return "SF15"
    case 10:
      return "RL4"
    case 11:
      return "RL8"
    case 12:
      return "RL12"
    case 13:
      return "RL16"
    case 14:
      return "IB5"
    case 15:
      return "IB8"
  }
}

export enum Liveness {
  Po_2 = 1,
  Po0 = 2,
  Po2 = 3,
  SS0 = 4,
  SS2_4 = 5,
  SS6_10 = 6,
  Lp0 = 7,
  Lp2 = 8,
  Lp4 = 9,
  RL4 = 10,
  RL8 = 11,
  RL12 = 12,
  RL16 = 13,
  IB5 = 14,
  IB8 = 15,
}

export interface CAge {
  node: {
    Po0: number
    Po2: number
    Po_2: number
    SS0: number
    SS2_4: number
    SS6_10: number
    Lp0: number
    Lp2: number
    Lp4: number
    RL4: number
    RL8: number
    RL12: number
    RL16: number
    IB5: number
    IB8: number
    age: number
  }
}

export interface MyLand {
  id: number
  landtype: LandType
  landsize: number
  species: TreeSpecies
  liveness: Liveness
  fertilizer: Fertilizer
}

export enum Fertilizer {
  Yes = 1,
  No = 0,
}

export enum TreeSpecies {
  AO,
  SG,
  SF,
  RL,
  IB,
}

export const Sop = 0.5

export interface Place {
  node: {
    code: string
    name: string
  }
}

/*
    {
    allCXlsxAnnually {
      edges {
        node {
          IB5
          IB8
          Lp0
          Lp2
          Lp4
          Po0
          Po2
          Po_2
          RL12
          RL16
          RL4
          RL8
          SS0
          SS2_4
          SS6_10
          age
        }
      }
    }
  }

0   age
1   Po_2
2   Po0
3   Po2
4   SS0
5   SS2_4
6   SS6_10
7   Lp0
8   Lp2
9   Lp4
10  RL4
11  RL8
12  RL12
13  RL16
14  IB5
15  IB8
  */
