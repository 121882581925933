import * as React from "react"
import { Component } from "react"
import { transpose, chain } from "mathjs"
//import styles from "./styles.module.scss"
import { GeoInfo, Groska } from "./"
import { LandType } from "./landtype"
import { AllCo2XlsxAnnually } from "./treeBondingChart"

import liveness_is, {
  TreeSpecies,
  Fertilizer,
  MyLand,
  Sop,
  Liveness,
} from "./liveness"
import MyLandComponent from "./myLand"
import MyLandsComponent from "./myLands"

export interface MyForestProps {
  geoinfo?: GeoInfo
  data: AllCo2XlsxAnnually
}

export interface MyForestState {
  mylands: MyLand[]
}

export default class MyForest extends Component<MyForestProps, MyForestState> {
  constructor(props: MyForestProps) {
    super(props)
    this.state = this.createDefaultMyForestState(this.props.geoinfo.groska)
    this.handleLandTypeChange = this.handleLandTypeChange.bind(this)
    this.handleLandSizeChange = this.handleLandSizeChange.bind(this)
    this.handleFertilizerChange = this.handleFertilizerChange.bind(this)
    this.handleAddMyLand = this.handleAddMyLand.bind(this)
    this.handleMyLandDelete = this.handleMyLandDelete.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.createDefaultMyForestState(nextProps.geoinfo.groska))
  }

  private createDefaultMyForestState(liveness: Groska): MyForestState {
    let i: number = 0
    let mls: MyLand[] = []
    if (liveness.AO) {
      i = i + 1
      mls.push({
        id: i,
        landtype: LandType.WellHeeledDryland,
        landsize: 1,
        species: TreeSpecies.AO,
        liveness: Liveness[liveness.AO],
        fertilizer: Fertilizer.Yes,
      })
    }
    if (liveness.SG) {
      i = i + 1
      mls.push({
        id: i,
        landtype: LandType.WellHeeledDryland,
        landsize: 1,
        species: TreeSpecies.SG,
        liveness: Liveness[liveness.SG],
        fertilizer: Fertilizer.Yes,
      })
    }
    if (liveness.SF) {
      i = i + 1
      mls.push({
        id: i,
        landtype: LandType.WellHeeledDryland,
        landsize: 1,
        species: TreeSpecies.SF,
        liveness: Liveness[liveness.SF],
        fertilizer: Fertilizer.Yes,
      })
    }
    if (liveness.RL) {
      i = i + 1
      mls.push({
        id: i,
        landtype: LandType.WellHeeledDryland,
        landsize: 1,
        species: TreeSpecies.RL,
        liveness: Liveness[liveness.RL],
        fertilizer: Fertilizer.Yes,
      })
    }
    if (liveness.IB) {
      i = i + 1
      mls.push({
        id: i,
        landtype: LandType.WellHeeledDryland,
        landsize: 1,
        species: TreeSpecies.IB,
        liveness: Liveness[liveness.IB],
        fertilizer: Fertilizer.Yes,
      })
    }
    let myForestState: MyForestState = { mylands: mls }
    return myForestState
  }

  private handleLandTypeChange(id: number, landtype: LandType) {
    this.setState({
      mylands: this.state.mylands.map(function(myland) {
        if (myland.id == id) {
          myland.landtype = landtype
          return myland
        } else return myland
      }),
    })
  }

  private handleLandSizeChange(id: number, landsize: number) {
    this.setState({
      mylands: this.state.mylands.map(function(myland) {
        if (myland.id == id) {
          myland.landsize = landsize
          return myland
        } else return myland
      }),
    })
  }

  private handleFertilizerChange(id: number, fertilizer: Fertilizer) {
    this.setState({
      mylands: this.state.mylands.map(function(myland) {
        if (myland.id == id) {
          myland.fertilizer = fertilizer
          return myland
        } else return myland
      }),
    })
  }

  private handleMyLandDelete(id: number) {
    let ms: MyLand[] = this.state.mylands.filter(function(myland) {
      return myland.id != id
    })
    this.setState({ mylands: ms })
  }

  private handleAddMyLand(event) {
    let new_id: number =
      chain(
        this.state.mylands.map(function(land) {
          return land.id
        })
      )
        .max()
        .done() + 1
    let ls = Liveness[String(event.target.value)]
    let ls_is: string = liveness_is(ls)

    let m = {
      id: new_id,
      landtype: LandType.WellHeeledDryland,
      landsize: 1,
      species: TreeSpecies[ls_is.substring(0, 2).replace(/Ö/gi, "O")],
      liveness: ls,
      fertilizer: Fertilizer.Yes,
    }
    let ms: MyLand[] = this.state.mylands
    ms.push(m)
    this.setState({ mylands: ms })
  }

  render() {
    let geoinfo = this.props.geoinfo
    const data = this.props.data
    let mylands = this.state.mylands
    const ages = [1, 2, 3, 4, 5].concat(
      data.allCo2XlsxAnnually.edges
        .filter(function({ node }) {
          return node.age < 51
        })
        .map(({ node }) => {
          return node.age
        })
    )
    let cDataTable: number[][] = []
    mylands.forEach(myland =>
      cDataTable.push(
        chain(
          [
            myland.fertilizer == 1 ? -0.03 : 0,
            0,
            0,
            0,
            myland.fertilizer == 1 ? -0.06 : 0,
          ].concat(
            chain(
              data.allCo2XlsxAnnually.edges
                .filter(function({ node }) {
                  return node.age < 51
                })
                .map(({ node }) => {
                  return node[Liveness[myland.liveness]]
                })
            )
              .add(Sop)
              .done()
          )
        )
          .add(myland.landtype)
          .multiply(myland.landsize)
          .done()
      )
    )
    let dataMean: number[] = chain(cDataTable)
      .mean(1)
      .done()
    cDataTable = transpose(cDataTable)
    cDataTable.push(dataMean)
    let dataSum: number[] = chain(cDataTable)
      .sum(1)
      .done()
    cDataTable = transpose(cDataTable)
    cDataTable.push(dataSum)
    cDataTable = chain(cDataTable).done()
    cDataTable = transpose(cDataTable)
    return (
      <div>
        <p>
          <b>
            Reiknaðu kolefnisbindingu fyrirhugaðrar skógræktar í tonnum CO
            <sub>2</sub>
          </b>
        </p>
        <p>
          Veldu fyrir hverja trjátegund þá landgerð sem fyrirfinnst á svæðinu, 
          fjölda hektara af viðkomandi landgerð og hvort bera á köfnunarefnisáburð 
          í upphafi skógræktar. Ef fleiri en ein landgerð er á svæðinu sem hentar 
          sömu trjátegund er hægt að bæta við línum og dálkum í töflunum hér 
          fyrir neðan með að ýta á „Bæta við“ hnappana.
        </p>
        <MyLandsComponent
          mylands={this.state.mylands}
          onLandTypeChange={this.handleLandTypeChange}
          onLandSizeChange={this.handleLandSizeChange}
          onFertilizerChange={this.handleFertilizerChange}
          onMyLandDelete={this.handleMyLandDelete}
        />
        <p>
          Bæta við{" "}
          {!(typeof this.props.geoinfo.groska.AO === "undefined" ||
              this.props.geoinfo.groska.AO == "") && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.AO]}
            value={this.props.geoinfo.groska.AO}
            onClick={this.handleAddMyLand}  
          >
            Alaskaösp
          </button>
          )}
          {!(typeof this.props.geoinfo.groska.SG === "undefined" ||
              this.props.geoinfo.groska.SG == "") && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.SG]}
            value={this.props.geoinfo.groska.SG}
            onClick={this.handleAddMyLand}
          >
            Sitkagreni
          </button>
              )}
          {!(typeof this.props.geoinfo.groska.SF === "undefined" ||
              this.props.geoinfo.groska.SF == "") && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.SF]}
            value={this.props.geoinfo.groska.SF}
            onClick={this.handleAddMyLand} 
          >
            Stafafuru
          </button>
          )}
          {!(typeof this.props.geoinfo.groska.RL === "undefined" ||
              this.props.geoinfo.groska.RL == "") && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.RL]}
            value={this.props.geoinfo.groska.RL}
            onClick={this.handleAddMyLand} 
          >
            Rússalerki
          </button>
          )}
          {!(typeof this.props.geoinfo.groska.IB === "undefined" ||
              this.props.geoinfo.groska.IB == "") && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.IB]}
            value={this.props.geoinfo.groska.IB}
            onClick={this.handleAddMyLand}   
          >
            Ilmbjörk
          </button>
          )}
        </p>
        {!Object.values(this.props.geoinfo.groskaMin).every(
          el => el === undefined) && (
        <p>
          <b>Athugið! </b>Skjól þarf að vera gott (Fjærskjól + (Nærskjól/2) ≥ 60)
           – Sjá kafla um skjólfar) til að tegundirnar sem birtast í töflunni hér
           fyrir ofan skili þeirri bindingu sem gefinn er upp. Ef skjól á staðnum
           er minna þarf að velja trjátegundir með hnöppunum hér fyrir neðan
           (Án skjóls) og fjarlæga sömu trjátegundir úr listanum fyrir ofan með
           Eyða hnappinum.
        </p>
        )}
        <p>
          { !Object.values(this.props.geoinfo.groskaMin).every(
          el => el === undefined) ? "Án skjóls " : ""}
          {!(typeof this.props.geoinfo.groskaMin.AO === "undefined" ||
              this.props.geoinfo.groskaMin.AO == "" ||
              this.props.geoinfo.groskaMin.AO == this.props.geoinfo.groska.AO) && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.AO]}
            value={this.props.geoinfo.groskaMin.AO}
            onClick={this.handleAddMyLand}
          >
            Alaskaösp
          </button>
              )}
          {!(typeof this.props.geoinfo.groskaMin.SG === "undefined" ||
              this.props.geoinfo.groskaMin.SG == "" ||
              this.props.geoinfo.groskaMin.SG == this.props.geoinfo.groska.SG) && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.SG]}
            value={this.props.geoinfo.groskaMin.SG}
            onClick={this.handleAddMyLand}
          >
            Sitkagreni
          </button>
          )}
          {!(typeof this.props.geoinfo.groskaMin.SF === "undefined" ||
              this.props.geoinfo.groskaMin.SF == "" ||
              this.props.geoinfo.groskaMin.SF == this.props.geoinfo.groska.SF) && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.SF]}
            value={this.props.geoinfo.groskaMin.SF}
            onClick={this.handleAddMyLand}   
          >
            Stafafuru
          </button>
          )}
          {!(typeof this.props.geoinfo.groskaMin.RL === "undefined" ||
              this.props.geoinfo.groskaMin.RL == "" ||
              this.props.geoinfo.groskaMin.RL == this.props.geoinfo.groska.RL) && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.RL]}
            value={this.props.geoinfo.groskaMin.RL}
            onClick={this.handleAddMyLand}
          >
            Rússalerki
          </button>
          )}
          {!(typeof this.props.geoinfo.groskaMin.IB === "undefined" ||
              this.props.geoinfo.groskaMin.IB == "" ||
              this.props.geoinfo.groskaMin.IB == this.props.geoinfo.groska.IB) && (
          <button
            type="button"
            name={TreeSpecies[TreeSpecies.IB]}
            value={this.props.geoinfo.groskaMin.IB}
            onClick={this.handleAddMyLand}
          >
            Ilmbjörk
          </button>
          )}
        </p>
        <table>
          <thead>
            <tr key={"age_treenames"}>
              <th>Aldur</th>
              {mylands.map(land => {
                return (
                  <th key={land.id + "-" + land.species}>
                    {land.id}-{TreeSpecies[land.species]}
                  </th>
                )
              })}
              <th>Samtals</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "right" }}>
            {cDataTable
              .filter(function(r, i) {
                return i < 50
              })
              .map((r, i) => {
                return (
                  <tr key={`age${i + 1}_tCO2`}>
                    <td>{i + 1}</td>
                    {r.map((c, ii) => {
                      return (
                        <td key={i + "-" + ii}>
                          {c.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            <tr key={`mean_tCO2`}>
              <td>Meðaltal</td>
              {cDataTable[50].map((c, i) => {
                return (
                  <td key={"mean" + i}>
                    {c.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
